import React, { useEffect, useState } from "react";
import stripHtml from "string-strip-html";
import { navigate } from "gatsby";
import { key, ghost } from "../constants";
import {
    getNPTH,
    getMiscTag,
    getIssue
} from "../utils";

const DomParser = require("dom-parser");
const parser = new DomParser();

const reg = new RegExp(
    /<!--kg-card-end: html-->(.+)<!--kg-card-begin: html-->/
);
const link = new RegExp(/src="(.+?)"/);

const getACF = (home) => {
    const dom = parser.parseFromString(home.node.html);
    // const heading0 =
    //     dom.getElementById("heading-0").innerHTML.match(reg)[1] || "";
    // const heading1 =
    //     dom.getElementById("heading-1").innerHTML.match(reg)[1] || "";
    const coverImage =
        (dom.getElementById("cover-image").innerHTML.match(reg) &&
            dom.getElementById("cover-image").innerHTML.match(reg)[1]) ||
        "";

    return {
        // heading0: stripHtml(heading0).result,
        // heading1: stripHtml(heading1).result,
        coverImage: coverImage ? coverImage.match(link)[1] : "",
    };
};

const FeaturedHero = ({post}) => {

    const genre = getNPTH(post) ? getNPTH(post).name : "";
    const tag = getMiscTag(post)
        ? getMiscTag(post).name
        : ""

    const issue = getIssue(post) ? getIssue(post).slug.split("-").pop() : ""

    return (
        <div id="featured-hero-row">
            <div id="featured-hero" style={{
                    backgroundImage: `url(${post.feature_image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center'
            }}>
            </div>
            <div className="featured-hero-text mt-4" style={{display: 'flex'}}>
                <div className="sub-head-light one-line">
                    {genre}
                </div>
                <div style={{margin: "0 6px"}}>{" | "}</div>
                <div className="sub-head-light one-line">
                    {tag}
                </div>
                <div style={{margin: "0 6px"}}>{" | "}</div>
                <div className="sub-head-light one-line">
                 {`ISSUE ${issue}`}
                </div>
            </div>
            <div>
                <div className="heading mt-4 one-line hover:underline cursor-pointer" onClick={() => navigate(post.slug)}>
                    {post.title}
                </div>
                <div className="sub-head-light mt-4 one-line hover:underline cursor-pointer" onClick={() => + navigate( "/author/" + post.authors.find(Boolean).slug)}>
                    by {post.authors.find(Boolean).name}
                </div>
            </div>
            <div className="sub-head mt-4 three-lines" >
                {post.excerpt}
            </div>
        </div>
    )
}

const Featured = ({post}) => {

    const genre = getNPTH(post) ? getNPTH(post).name : "";
    const tag = getMiscTag(post)
        ? getMiscTag(post).name
        : ""
    const issue = getIssue(post) ? getIssue(post).slug.split("-").pop() : ""

    console.log("POST", post)

    return (
        <div style={{display: 'flex'}} className="featured-image-row">
        <div id="featured-image" style={{
                    backgroundImage: `url(${post.feature_image})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center'
                }}>
        </div>
        <div className="featured-image-row-container">

                <div style={{display: "flex"}}>
                    <div className="sub-head-light one-line">
                        {genre}
                    </div>
                    <div style={{margin: "0 6px"}}>{" | "}</div>
                    <div className="sub-head-light one-line">
                        {tag}
                    </div>
                    <div style={{margin: "0 6px"}}>{" | "}</div>

                    <div className="sub-head-light one-line">
                        {`ISSUE ${issue}`}
                    </div>
                </div>
                <div>
                    <div className="heading-small mt-4 one-line hover:underline cursor-pointer" onClick={() => navigate(post?.slug)}>
                        {post?.title}
                    </div>
                    <div className="sub-head-light mt-4 one-line hover:underline cursor-pointer" onClick={() => navigate("/author/" + post.authors.find(Boolean).slug)}>
                        by {post.authors.find(Boolean).name}
                    </div>
                </div>
                <div className="sub-head mt-4 three-lines">
                    {post.excerpt}
                </div>
            </div>
        </div>
    )
}

const hasTag = (post, tag) => post.tags.find(t => t.name.includes(tag));

export default ({home, data}) => {

    console.log(data.allGhostPost)
    const [random, setRandom] = useState("");
    const { heading0, heading1, coverImage } = getACF(home);

    const featured = data.allGhostPost.edges.filter(({node})=> {
        return node.tags.find(t => t.name === "home-page-0" || t.name === "home-page-1" || t.name === "home-page-2" || t.name === "home-page-3")
    }).map(p => p.node) || [];

    const issues = data?.allGhostTag?.nodes?.filter((t) => {
        return t.slug.includes("issue");
    });

    const posts = data?.allGhostTag?.nodes?.filter((t) => {
        return t.slug.includes("issue");
    });

    issues?.sort(
        (a, b) => parseInt(b.slug.split("-").pop()) - parseInt(a.slug.split("-").pop())
    );

    const latest = issues.find(Boolean);
    const latestNum = latest.slug.split("-").pop();

    const getRandom = async () => {
        try {
            const response = await fetch(ghost + "/posts/?key=" + key);
            const { posts } = await response.json();
            const post = posts[Math.floor(Math.random() * posts.length)];

            setRandom(post.slug);
        } catch (err) {
            console.error(err.toString());
        }
    };
    useEffect(() => {
        getRandom();
    }, []);

    return (
        <div
            id="home"
            className="flex flex-col"
        >
               <div id="hero-section-desktop" className="flex">
                   <div id="hero-text-container">
                        <div id="hero-text-desktop" className="font-serif" style={{lineHeight: '84px', fontSize: 64, fontWeight: 700, position: 'absolute', backgroundColor: 'rgba(255,255,255, .7)'}}>
                            <div style={{padding: '6rem', position: 'relative', width: '100%', height: '100%'}}>
                            <div>
                                <div>
                                 A magazine by and for rational optimists.
                                 </div>                            
                                <button className="primary-button font-sans mt-24" onClick={() => navigate(`/${latest.slug}`)}>
                                    READ ISSUE {latestNum}
                                </button>
                            </div>
                            </div>
                        </div>
                        <div id="hero-text-mobile" style={{padding: '2rem 4rem'}}>
                        <div>
                                <div>
                                 A magazine by and for rational optimists.
                                 </div>                            
                                <button className="primary-button font-sans mt-24" onClick={() => navigate(`/${latest.slug}`)}>
                                    READ ISSUE {latestNum}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="hero-image-container">
                        <img src={coverImage}/>
                        <div style={{display: 'flex', position: 'absolute', bottom: '-8px'}}>
                            <div className="hp-nav-element ml-4 font-sans hover:underline cursor-pointer" onClick={() => window.open("https://thedial.us/about.html")}>
                                Our story
                            </div>
                            <div className="hp-nav-element ml-4 font-sans hover:underline cursor-pointer" onClick={() => navigate("/" + latest?.slug)}>
                                Latest issue
                            </div>
                            <div className="hp-nav-element ml-4 font-sans hover:underline cursor-pointer" onClick={() => window.open("https://thedial.us")}>
                                The Dial
                            </div>
                        </div>
                    </div>
               </div>
               <div id="hero-section-mobile">
                   <div>
                   <div id="hero-image-container">
                        <img src={coverImage}/>
                        {/* <div style={{display: 'flex', position: 'absolute', bottom: '-8px'}}>
                            <div className="hp-nav-element ml-4 font-sans hover:underline cursor-pointer" onClick={() => window.open("https://thedial.us/about.html")}>
                                Our story
                            </div>
                            <div className="hp-nav-element ml-4 font-sans hover:underline cursor-pointer" onClick={() => navigate("/" + latest?.slug)}>
                                Latest issue
                            </div>
                            <div className="hp-nav-element ml-4 font-sans hover:underline cursor-pointer" onClick={() => window.open("https://thedial.us")}>
                                The Dial
                            </div>
                        </div> */}
    
                    </div>
                        <div className="hero-text-mobile">
                            <div className="font-serif" id="hero-text">
                                A magazine by and for rational optimists.
                            </div>  
                            <div className="flex items-center justify-center">                       
                                <button className="primary-button font-sans mt-12" onClick={() => navigate(`/${latest.slug}`)}>
                                    READ ISSUE {latestNum}
                                </button>
                            </div>   
                            </div>
                   </div>
               </div>
               <div id="current-issue-section">
                   <div id="current-issue-section-text-block" style={{display: 'flex', flexDirection: 'column'}}>
                    
                    <div className="sub-head-light mt-4">
                        {latest.description.split(/:(.+)/)[0]}
                    </div>
                    <div className="heading mt-4">
                        {latest.name}
                    </div>
                    <div id="current-issue-section-image-container-mobile">
                                <img src={`${latest.feature_image}`} style={{width: '60%'}} />

                        {/* <div id="current-issue-section-image" style={{background: `url("${latest.feature_image}") 50% 50% no-repeat`}}> */}
                        {/* </div> */}
                    </div>
                    <div id="current-issue-description" className="sub-head">
                        {latest.description.split(/:(.+)/)[1]}
                    </div>
                    <div id="read-issue-button" className="primary cursor-pointer hover:underline" onClick={() => navigate("/" + latest.slug)}>
                        READ ISSUE {latestNum}
                    </div>
                   </div>
                   <div id="current-issue-section-image-container-desktop" >
                    <img src={`${latest.feature_image}`} style={{width: '60%'}} />
                    </div>
               </div>
               <div id="current-issue-content-section-one" >
                   <div className="featured-hero-column">
                   <FeaturedHero 
                            post={featured.find(p => hasTag(p, "home-page-0"))}
                            issue={latestNum}
                        />
                   </div>

                   {console.log("home-page-0", featured.find(p => hasTag(p, "home-page-0")))}
                   {console.log("home-page-1", featured.find(p => hasTag(p, "home-page-1")))}
                   {console.log("home-page-2", featured.find(p => hasTag(p, "home-page-2")))}
                   {console.log("home-page-3", featured.find(p => hasTag(p, "home-page-3")))}

                   <div className="featured-image-column">
                       <Featured 
                            post={featured.find(p => hasTag(p, "home-page-1"))}
                            issue={latestNum}
                      />
                        <Featured 
                            issue={latestNum}
                            post={featured.find(p => hasTag(p, "home-page-2"))}
                        />
                        <Featured 
                            issue={latestNum}
                            post={featured.find(p => hasTag(p, "home-page-3"))}

                        />
                   </div>
               </div>
               {/* <div id="newsletter-section">
                   hello
               </div> */}
        </div>
    );
};
